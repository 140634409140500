import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Импортируем BrowserRouter, Routes и Route
import VerificationPage from './components/VerificationPage';
import OrderInfoPage from './components/Orders/OrderInfo/OrderInfo'; // Импортируем страницу с информацией о заказе
import ServicePage from './components/CdekIntegration/Widgetwebapp';
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Маршрут для страницы с информацией о заказе */}
        <Route path="/order_info/:id" element={<OrderInfoPage />} />
        {/* Маршрут для страницы верификации */}
        <Route path="/admin" element={<VerificationPage />} />
        {/* Маршрут для веб виджета */}
        <Route path="/map" element={<ServicePage />} />
        {/* Маршрут для виджета менеджера */}
        <Route path="/create" element={<ServicePage />} />
      </Routes>
    </Router>
  );
}

export default App;
