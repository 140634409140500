import React, { useState } from 'react';
import './AdminPanel.css'; 




const AdminPanel = () => {
    const [selectedTab, setSelectedTab] = useState('subscriptions');

    // Объект для управления содержимым вкладок
    const tabsContent = {
      subscriptions: (
        <div>
          <h2>Тут будет отображаться общая статистика</h2>
        </div>
      ),
      users: (
        <div>
          <h2>Пользователи</h2>
        </div>
      ),
      victorina: (
        <div>


        </div>
      )
    };

  return (
    <div className="admin-panel">
      <div className="sidebar">
        <h2>SwichSaller</h2>
        <p>Меню:</p>
        <button onClick={() => setSelectedTab('subscriptions')} className="sidebar-button">Общая статистика</button>
        <button onClick={() => setSelectedTab('users')} className="sidebar-button">Пользователи</button>

      </div>
      <div className="content">
        <h1>Админ-панель</h1>
        {tabsContent[selectedTab] || <div>Выберите вкладку</div>}
      </div>
    </div>
  );
};

export default AdminPanel;
