import React, { useEffect, useState } from 'react';
import './Widget.css';

const Widgetwebapp = () => {
  const [tgViewportHeight, setTgViewportHeight] = useState(null);
  let tg; // Объявляем переменную tg здесь

  const parseTelegramInitData = (queryString) => {
    const params = new URLSearchParams(queryString);
    const result = {};

    for (const [key, value] of params.entries()) {
      try {
        result[key] = JSON.parse(decodeURIComponent(value));
      } catch {
        result[key] = decodeURIComponent(value);
      }
    }

    return result;
  };

  const sendAddressToTelegram = async (address, telegramInitData) => {
    try {
      const response = await fetch('https://swich.plutus-fin.ru/api/sendAddressToTelegram', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          address: address,
          telegram_init_data: telegramInitData
        })
      });
      const data = await response.json();
      console.log('Server response:', data);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  useEffect(() => {
    // Код для загрузки скрипта Telegram Web App
    const telegramScript = document.createElement('script');
    telegramScript.src = 'https://telegram.org/js/telegram-web-app.js';
    telegramScript.async = true;

    // Добавляем обработчик события onload для скрипта Telegram Web App
    telegramScript.onload = () => {
      // Теперь, когда скрипт Telegram Web App загружен, можем получить доступ к его объектам и методам
      tg = window.Telegram.WebApp; // Присваиваем tg глобальной переменной
      setTgViewportHeight(tg.viewportHeight);

      tg.expand();


    };

    // Добавляем скрипт Telegram Web App в body
    document.body.appendChild(telegramScript);

    // Очистка при размонтировании компонента
    return () => {
      document.body.removeChild(telegramScript);
    };
  }, []);

  useEffect(() => {
    // Код для загрузки и инициализации виджета CDEK
    const cdekScript = document.createElement('script');
    cdekScript.type = 'text/javascript';
    cdekScript.src = 'https://cdn.jsdelivr.net/npm/@cdek-it/widget@3';
    cdekScript.charset = 'utf-8';

    // Функция, которая будет выполнена после загрузки скрипта CDEK
    const onLoadCDEKScript = () => {
      new window.CDEKWidget({
        debug: true,
        from: {
          country_code: 'RU',
          city: 'Новосибирск',
          postal_code: 630009,
          code: 270,
          address: 'ул. Большевистская, д. 101',
        },
        root: 'cdek-map',
        // apiKey: 'a207c021-8fb5-41eb-b00e-19bfeb63c1de',
        apiKey: 'b351eb1b-966c-4115-b9c0-d775896ee3ee',
        canChoose: true,
        servicePath: 'https://swich.plutus-fin.ru/service.php',
        hideDeliveryOptions: {
          office: false,
          door: true,
        },
        lang: 'rus',
        currency: 'RUB',
        tariffs: {
          office: [136]
        },
        forceFilters: {
          type: `PVZ`,
        },
        defaultLocation: 'Новосибирск',
        onChoose(deliveryMode, selectedTariff, selectedAddress) {
          if (deliveryMode === 'office') {
            console.log('Код пункта выдачи заказов:', selectedAddress.code);
            console.log(tg);
            if (tg) { // Проверяем, что tg инициализирован
              console.log(`Сдек инициализирован`)
              // tg.sendData(selectedAddress.code);
              const parsedTelegramInitData = parseTelegramInitData(tg.initData);
              sendAddressToTelegram(selectedAddress.code, parsedTelegramInitData)
            }
          }
        },
      });
    };

    // Слушатель события загрузки скрипта CDEK
    cdekScript.onload = onLoadCDEKScript;

    // Добавляем скрипт CDEK в head
    document.head.appendChild(cdekScript);

    // Очистка при размонтировании компонента
    return () => {
      document.head.removeChild(cdekScript);
    };
  }, []);

  return (
    <div id="cdek-map" style={{ width: '100%', height: `${tgViewportHeight + 60}px` }}>
      {/* Этот div будет использоваться для отображения виджета */}
    </div>
  );
};

export default Widgetwebapp;
